import React, { useEffect, useState } from 'react';
import { Drawer } from 'antd';
import dropdownPlayStore from '../assets/hq/dropdownPlayStore.png';
import dropdownAppStore from '../assets/hq/dropdownAppStore.png';
import dropdownDapp from '../assets/hq/dropdownDapp.png';
import dropdownChromeExt from '../assets/hq/dropdownChromeExt.png';
import dropdownBusiness from '../assets/hq/dropdownBusiness.png';
import { RedirectURLs } from '../constants/redirectURLS';
import { logAnalytics } from '../util';
import { useIsBrowser } from '../hooks/useIsBrowser';

export default function GetStartedDrawer({ open, setOpen }) {
  const isBrowser = useIsBrowser();
  const [innerWidth, setInnerWidth] = useState(0);

  useEffect(() => {
    if (isBrowser.current) {
      setInnerWidth(window.innerWidth);
    }
  }, []);

  return (
    <Drawer
      placement={innerWidth <= 640 ? 'bottom' : 'right'}
      closable={false}
      onClose={() => {
        setOpen(false);
      }}
      open={open}
      className='rounded-[12px]'
      height={500}
    >
      <div className='space-y-[12px] w-full max-h-max'>
        <h2 className='font-semibold text-[20px]'>{'Lets get started'}</h2>
        {isBrowser.current && navigator.userAgent.indexOf('Android') !== -1 && (
          <div
            className='cursor-pointer'
            onClick={() => {
              logAnalytics({
                category: 'navbar',
                action: 'click link to playstore',
                label: 'install from playstore',
              });
            }}
          >
            <a href={RedirectURLs.playStore} target='_blank'>
              <img
                src={dropdownPlayStore}
                alt='Play Store'
                title='Play Store'
                className='w-full'
                width='100%'
                height='auto'
              />
            </a>
          </div>
        )}
        {isBrowser.current &&
          navigator.userAgent.indexOf('like Mac') !== -1 && (
            <div
              className='cursor-pointer'
              onClick={() => {
                logAnalytics({
                  category: 'navbar',
                  action: 'click link to app store',
                  label: 'install from appstore',
                });
              }}
            >
              <a href={RedirectURLs.appStore} target='_blank'>
                <img
                  src={dropdownAppStore}
                  alt='App Store'
                  title='App Store'
                  className='w-full'
                  width='100%'
                  height='auto'
                />
              </a>
            </div>
          )}
        <div
          className='cursor-pointer'
          onClick={() => {
            logAnalytics({
              category: 'navbar',
              action: 'click link to dapp',
              label: 'cypher dapp',
            });
          }}
        >
          <a href={RedirectURLs.dApp} target='_blank'>
            <img
              src={dropdownDapp}
              alt='Cypher dApp'
              title='Cypher dApp'
              className='w-full'
              width='100%'
              height='auto'
            />
          </a>
        </div>
        <div
          className='cursor-pointer'
          onClick={() => {
            logAnalytics({
              category: 'navbar',
              action: 'click link to chrome extension',
              label: 'chrome extension',
            });
          }}
        >
          <a href={RedirectURLs.extension} target='_blank'>
            <img
              src={dropdownChromeExt}
              alt='Chrome Extension'
              title='Chrome Extension'
              className='w-full'
              width='100%'
              height='auto'
            />
          </a>
        </div>
        <h2 className='font-semibold text-[20px]'>{'Business solution'}</h2>
        <div
          className='cursor-pointer'
          onClick={() => {
            logAnalytics({
              category: 'navbar',
              action: 'click link to business',
              label: 'cypher business',
            });
          }}
        >
          <a href={RedirectURLs.business} target='_blank'>
            <img
              src={dropdownBusiness}
              alt='Cypher Business'
              title='Cypher Business'
              className='w-full'
              width='100%'
              height='auto'
            />
          </a>
        </div>
      </div>
    </Drawer>
  );
}
